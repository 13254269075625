<template>
    <DashboardContainer>
        <ManagersGrid/>
        <AddManager/>
    </DashboardContainer>
</template>

<script>
import DashboardContainer from '../../containers/DashboardContainer'
import ManagersGrid from '../../components/admin/managers/ManagersGrid'
import AddManager from '../../components/admin/managers/AddManager'
//import axios from 'axios'

export default {
    //name: 'Member',
    beforeCreate: function () {
        var auth = this.$storage.get('auth')
        if (!auth) {
            this.$router.push('/login')
        }else{
            if(!auth.token){
                this.$router.push('/login')
            }
        }
    },
    // data(){
	// 	return{
    //         leadSources:[],
    //         lPermissions:[],
	// 	}
    // },
    components: {
        DashboardContainer,
        ManagersGrid,
        AddManager,
    },
    // created(){
    //     this.getLeadSource()
    //     this.getPermissions()
	// },
    // methods:{
    //     getLeadSource(){
	// 		axios
	// 		.get('lead_sources')
	// 		.then( res => {
    //             if (res.data.success) {
    //                 this.leadSources = res.data.success
    //             }
	// 		})
	// 		.catch(err =>{
	// 			console.log(err)
	// 		})
    //     },
    //     getPermissions(){
	// 		axios
	// 		.get('permissions',{headers: {Authorization:'Bearer '+this.$storage.get('auth').token}})
	// 		.then( res => {
    //             if (res.data.success) {
    //                 this.lPermissions = res.data.success
    //             }
	// 		})
	// 		.catch(err =>{
	// 			console.log(err)
	// 		})
    //     }
    // },
}
</script>