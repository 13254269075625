<template>
  <div class="saleteam-area">
    <!-- managers -->
    <!-- {{members}} -->
    <div class="teams managers" v-for="manager in members" :key="manager.id">
      <div
        class="delete-member blue-circle"
        v-on:click="deleteManager(manager)"
      >
        <img class="img-fluid" src="../../../assets/img/Delete.svg" alt="" />
      </div>
      <div class="edit-member blue-circle" v-on:click="updateMember(manager)">
        <img class="img-fluid" src="../../../assets/img/pencil.svg" alt="" />
      </div>
      <div class="team-img">
        <img v-if="manager.picture" class="img-fluid" :src="manager.picture" />
        <img
          v-else
          class="img-fluid"
          src="../../../assets/img/profile.svg"
          alt=""
        />
      </div>

      <div class="team-title">
        <h3>{{ manager.name }}</h3>
        <div
          class="member-status"
          :class="manager.user_status == 1 ? 'text-success' : 'text-danger'"
        >
          <span v-if="manager.user_status == 1">Active</span>
          <span v-if="manager.user_status == 0">InActive</span>
        </div>
        <p>{{ manager.created | formatDate("MMMM DD, YYYY") }}</p>
      </div>
    </div>
    <!-- managers -->

    <!-- Add manager -->
    <div class="teams managers">
      <div class="add-new">
        <a
          href="#"
          data-toggle="modal"
          data-target="#addManagerModal"
          data-backdrop="static"
          data-keyboard="false"
          ><img class="img-fluid" src="../../../assets/img/240.svg" alt=""
        /></a>
      </div>
    </div>
    <!-- Add manager -->

    <div
      class="modal fade"
      id="updateMemeber"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Update Manager</h5>
            <a
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              v-on:click="cancelCrop"
            >
              <span aria-hidden="true"
                ><img
                  class="img-fluid"
                  src="../../../assets/img/23. Closex.svg"
                  alt=""
              /></span>
            </a>
          </div>
          <form
            @submit.prevent="updateMemberProcess"
            enctype="multipart/form-data"
          >
            <div class="modal-body">
              <div v-show="doCrop">
                <vue-cropper
                  :aspect-ratio="1 / 1"
                  :view-mode="1"
                  ref="cropper"
                  :src="cropImgUrl"
                  alt="Source Image"
                ></vue-cropper>
              </div>
              <div v-show="!doCrop">
                <div class="user-img">
                  <div class="button-wrapper">
                    <span class="label">
                      <template v-if="cropImgUrl">
                        <img class="img-fluid" :src="cropImgUrl" alt="" />
                      </template>
                      <template v-else-if="member.picture">
                        <img class="img-fluid" :src="member.picture" alt="" />
                      </template>
                      <template v-else>
                        <img
                          v-if="!doCrop"
                          class="img-fluid"
                          :src="imgUrl"
                          alt=""
                        />
                      </template> </span
                    ><input
                      type="file"
                      ref="file"
                      accept="image/*"
                      v-on:change="handleFileUpload"
                      class="upload upload-box"
                      placeholder="Upload File"
                    />
                  </div>
                  <!-- <div class="edit-pro">
                                <img class="img-fluid" src="../../../assets/img/edit.svg" alt="">
                            </div> -->
                </div>
                <div class="add-member">
                  <div class="form-row">
                    <div class="form-group col-md-6">
                      <label>First Name</label>
                      <input
                        v-model="member.first_name"
                        type="text"
                        class="form-control"
                        placeholder="Jeanne"
                      />
                      <div
                        class="validate-error-message"
                        v-if="$vd.member.first_name.$hasError === true"
                      >
                        {{ $vd.member.$errors.first_name[0] }}
                      </div>
                    </div>
                    <div class="form-group col-md-6">
                      <label>Last Name</label>
                      <input
                        v-model="member.last_name"
                        type="text"
                        class="form-control"
                        placeholder="Acevedo"
                      />
                      <div
                        class="validate-error-message"
                        v-if="$vd.member.last_name.$hasError === true"
                      >
                        {{ $vd.member.$errors.last_name[0] }}
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Email Address</label>
                    <input
                      v-model="member.email"
                      type="email"
                      class="form-control"
                      placeholder="example@gmail.com"
                    />
                    <div
                      class="validate-error-message"
                      v-if="$vd.member.email.$hasError === true"
                    >
                      {{ $vd.member.$errors.email[0] }}
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-md-6">
                      <label>Password</label>
                      <input
                        v-model="member.password"
                        type="password"
                        class="form-control"
                        placeholder="Enter password"
                      />
                      <div
                        class="validate-error-message"
                        v-if="$vd.member.password.$hasError === true"
                      >
                        {{ $vd.member.$errors.password[0] }}
                      </div>
                    </div>
                    <div class="form-group col-md-6">
                      <label>Confirm Password</label>
                      <input
                        v-model="member.cpassword"
                        type="password"
                        class="form-control"
                        placeholder="Enter password"
                      />
                      <div
                        class="validate-error-message"
                        v-if="$vd.member.cpassword.$hasError === true"
                      >
                        {{ $vd.member.$errors.cpassword[0] }}
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Phone number</label>
                    <!-- <vue-tel-input v-model="register_.phone" v-bind="{mode:'international',dynamicPlaceholder:true,inputId:'phone',enabledCountryCode:false,maxLen:15,validCharactersOnly:true,onlyCountries:['us'],defaultCountry:'us',placeholder:'Enter a phone number'}" ></vue-tel-input> -->
                    <input
                      type="tel"
                      v-model="member.phone"
                      class="form-control"
                    />
                    <div
                      class="validate-error-message"
                      v-if="$vd.member.phone.$hasError === true"
                    >
                      {{ $vd.member.$errors.phone[0] }}
                    </div>
                  </div>

                  <div class="form-group">
                    <multi-select
                      :searchable="true"
                      id="update_user"
                      textColumn="text"
                      placeholder="Select Dealers"
                      :alreadySelected="selected_dealers"
                      v-model="member.by"
                      :options="allDealers"
                      label="Dealers"
                    ></multi-select>
                  </div>
                  <!-- <div class="form-group">
                                <label for="role_title">Role</label>
                                <input v-model="register_.role" type="text" class="form-control" id="role_title" placeholder="Regional Manager">
                                <div class="validate-error-message" v-if="$vd.register_.role.$hasError === true">{{$vd.register_.$errors.role[0]}}</div>
                            </div> -->
                  <!-- <div class="form-group">
                                <label>Role</label>
                                <select class="form-control" v-model="register_.role" >
                                    <option value="">Select user role</option>
                                    <option v-for="role in roles" :value="role.id" :key="role.id">{{role.role_title}}</option>
                                </select>
                                <div class="validate-error-message" v-if="$vd.register_.role.$hasError === true">{{$vd.register_.$errors.role[0]}}</div>
                            </div> -->

                  <!-- <div class="form-group">
                                <multi-select :searchable="true" id="lead_permissions" textColumn="text" :options="permissions" v-model="register_.permissions" label="Permissions" placeholder="Add permissions"></multi-select>
                            </div>
                            <div class="form-group">
                                <multi-select :searchable="true" id="lead_source" textColumn="ls_name" placeholder="Select your lead source" v-model="register_.lsources" :options="LSources" label="Lead source"></multi-select>
                                <div class="validate-error-message" v-if="$vd.register_.lsources.$hasError === true">{{$vd.register_.$errors.lsources[0]}}</div>
                            </div> -->
                  <!-- <div class="form-group">
                                <div class="Permis-btns">
                                    <a class="Permis-temp" href="#">Temporarily suspended</a>
                                    <a class="Permis-assing" href="#" data-toggle="modal" data-target="#exampleModal2" data-dismiss="modal" aria-label="Close">Assign</a>
                                </div>
                            </div> -->
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <!-- <a href="#" class="add-btn"><img class="img-fluid" src="../../../assets/img/22. Delete.svg" alt=""> Cancel</a> -->
              <button type="submit" class="rem-btn" v-if="!doCrop">
                <img
                  class="img-fluid"
                  src="../../../assets/img/23. Close.svg"
                  alt=""
                />
                Update
              </button>
              <a
                href="#"
                v-if="doCrop"
                data-dismiss="modal"
                v-on:click="cancelCrop"
                class="add-btn"
                ><img
                  class="img-fluid"
                  src="../../../assets/img/22. Delete.svg"
                  alt=""
                />
                Cancel</a
              >
              <a
                type="button"
                v-on:click="cropIt"
                class="rem-btn"
                v-if="doCrop"
              >
                Crop
              </a>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- update-member Modal -->
  </div>
</template>

<script>
import axios from "axios";
import VueDaval from "vue-daval";
import $ from "jquery";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import MultiSelect from "../../helpers/MultiSelect";
//import VueDaval from 'vue-daval'

export default {
  name: "ManagersGrid",
  //mixins: [ VueDaval ],
  //props:['LSources','permissions'], comaSplit,simplebar
  components: { VueCropper, MultiSelect },
  mixins: [VueDaval],
  data() {
    return {
      members: [],
      member: {
        id: "",
        picture: "",
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        password: "",
        cpassword: "",
        dealer_id: "",
        by: [],
        alreadyAssigned: [],
      },
      selected_dealers: [],
      allDealers: [],
      imgUrl: require("../../../assets/img/profile.svg"),
      cropImgUrl: null,
      doCrop: false,
      // updMember_:  {
      //     id:'',img:'',first_name:'',last_name:'',email:'',phone:'',role_title:'',status:1,permissions:[],lsources:[],sources:[],team:this.$route.params.slug
      // },
      // team_name:'',
      // imgUrl: require('../../../assets/img/profile.svg'),
      // cropImgUrl: null,
      // doCrop:false,
      // team_id: null
    };
  },
  vdRules: {
    member: {
      first_name: { required: true },
      last_name: { required: true },
      email: { required: true, type: "email" },
      password: { required: true, minlen: 8 },
      cpassword: { required: true, equals: "member.password" },
      phone: { required: true },
    },
  },
  created() {
    this.getTeamMembers();
    this.getDealer();
    let vm = this;
    this.$root.$on("action", function (action) {
      if (action == "newManager") {
        vm.getTeamMembers();
      }
    });
  },
  watch: {
    "$route.params.id": function () {
      this.getTeamMembers();
    },
  },
  methods: {
    deleteManager(manager) {
      this.$confirm({
        title: `Are you sure?`,
        message: "you want to delete this user?",
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            //console.log(member)
            axios
              .post(
                "delete/manager",
                { id: manager.id },
                {
                  headers: {
                    Authorization: "Bearer " + this.$storage.get("auth").token,
                  },
                }
              )
              .then((res) => {
                if (res.data.success) {
                  this.$toast.open({
                    position: "top-right",
                    message: res.data.message,
                    type: "success",
                  });
                  this.getTeamMembers();
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        },
      });
    },
    getDealer() {
      axios
        .get("all_dealers", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          const data = res.data;
          if (data.success) {
            if (data.success.length > 0) {
              var dd = [];
              $.each(data.success, function (key, value) {
                dd.push({ id: value.dl_id, text: value.dl_name });
              });
              this.allDealers = dd;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getDealersByManager(id) {
      axios
        .get("get/dealers/" + id, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          const data = res.data;
          if (data.success) {
            if (data.success.length > 0) {
              var sd = [];
              $.each(data.success, function (key, value) {
                sd.push({ id: value.dl_id, text: value.dl_name });
              });
              this.selected_dealers = sd;
              this.member.alreadyAssigned = sd;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getTeamMembers() {
      axios
        .get("admin/managers/" + this.$route.params.id, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          const data = res.data;
          if (data.success) {
            this.members = data.success;

            console.log(this.members);
            //this.team_name = data.success.team_name
            //this.$root.$emit('pTitle', this.members.)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateMember(member) {
      console.log(member);
      this.member.id = member.id;
      this.member.first_name = member.first_name;
      this.member.last_name = member.last_name;
      this.member.email = member.email;
      this.member.phone = member.phone;
      this.member.picture = member.picture;
      this.member.dealer_id = this.$route.params.id;
      this.getDealersByManager(member.id);
      $("#updateMemeber").modal("show");
    },
    updateMemberProcess() {
      console.log(this.member);
      this.$vd.member
        .$validate()
        .then(() => {
          axios
            .post("update_Member", this.member, {
              headers: {
                Authorization: "Bearer " + this.$storage.get("auth").token,
              },
            })
            .then((res) => {
              if (res.data.status == "success") {
                $("#updateMemeber").modal("hide");
                this.getTeamMembers();
                this.member = {
                  id: "",
                  picture: "",
                  first_name: "",
                  last_name: "",
                  email: "",
                  phone: "",
                  dealer_id: "",
                  by: [],
                  alreadyAssigned: [],
                };
              } else if (res.data.status == "pnm") {
                alert("Password does not match");
              } else if (res.data.validate) {
                alert(res.data.validate);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {});
    },
    handleFileUpload(e) {
      const file = e.target.files[0];
      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }

      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.cropImgUrl = event.target.result;
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result);
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
      this.doCrop = true;

      //this.imgUrl = URL.createObjectURL(this.$refs.file.files[0]);
    },
    cropIt() {
      this.cropImgUrl = this.$refs.cropper.getCroppedCanvas().toDataURL();
      this.member.img = this.$refs.cropper.getCroppedCanvas().toDataURL();
      this.doCrop = false;
    },
    cancelCrop() {
      this.doCrop = false;
    },
  },
};
</script>
<style scoped>
.managers {
  height: 240px !important;
  padding: 25px 0 !important;
}
</style>